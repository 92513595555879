"use client";

import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { IconFaqtory } from "@repo/icons/custom";
import { Button } from "@repo/ui/button";
import { MobileNavbar } from "@/components/mobile-navbar";

export function Header() {
  const posthog = usePostHog();

  const captureEvent = (
    eventName: string,
    properties?: Record<string, any>
  ) => {
    posthog?.capture(eventName, properties);
  };

  return (
    <header className="container flex items-center justify-between gap-10 py-4">
      <Link
        href="/"
        className="flex items-center gap-3"
        onClick={() => captureEvent("logo_clicked")}
      >
        <IconFaqtory className="w-40" />
      </Link>
      <div className="flex items-center gap-10">
        <nav className="hidden items-center justify-end gap-10 md:flex">
          <Link
            href="/overview"
            target="_blank"
            className="hover:text-foreground flex cursor-pointer items-center text-lg font-medium transition-colors sm:text-lg"
            onClick={() =>
              captureEvent("nav_link_clicked", { link: "company_overview" })
            }
          >
            Company Overview
          </Link>
          <Link
            href="#features"
            className="hover:text-foreground flex cursor-pointer items-center text-lg font-medium transition-colors sm:text-lg"
            onClick={() =>
              captureEvent("nav_link_clicked", { link: "features" })
            }
          >
            Features
          </Link>
          <Link
            href="#pricing"
            className="hover:text-foreground flex cursor-pointer items-center text-lg font-medium transition-colors sm:text-lg"
            onClick={() =>
              captureEvent("nav_link_clicked", { link: "pricing" })
            }
          >
            Pricing
          </Link>
        </nav>
      </div>
      <MobileNavbar>
        <div className="bg-background text-foreground container rounded-b-lg py-4 shadow-xl">
          <nav className="flex flex-col gap-1 pt-2">
            <Link
              href="/overview"
              target="_blank"
              className="hover:text-foreground flex cursor-pointer items-center text-lg font-medium transition-colors sm:text-lg"
            >
              Company Overview
            </Link>
            <Link
              href="#features"
              className="hover:text-foreground flex cursor-pointer items-center text-lg font-medium transition-colors sm:text-lg"
            >
              Features
            </Link>
            <Link
              href="#pricing"
              className="hover:text-foreground flex cursor-pointer items-center text-lg font-medium transition-colors sm:text-lg"
            >
              Pricing
            </Link>
          </nav>
        </div>
      </MobileNavbar>
    </header>
  );
}
