import(/* webpackMode: "eager", webpackExports: ["CtaSection"] */ "/vercel/path0/apps/faqtory/src/components/cta-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Features"] */ "/vercel/path0/apps/faqtory/src/components/features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/apps/faqtory/src/components/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/apps/faqtory/src/components/header-alt.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/apps/faqtory/src/components/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Pricing"] */ "/vercel/path0/apps/faqtory/src/components/pricing.tsx");
