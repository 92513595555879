"use client";

import Link from "next/link";
import { Button } from "@repo/ui/button";

export function CtaSection() {
  return (
    <section className="container relative flex flex-col items-center gap-6 py-20 sm:gap-10">
      <h2 className="font-heading max-w-xl text-center text-3xl font-semibold sm:text-4xl sm:leading-tight">
        Question In, Answer Out!
      </h2>
      <p className="text-muted-foreground max-w-xl text-center text-lg">
        Stop losing time, answering the same questions over and over.
        Faqtory&apos;s AI-powered FAQs will answer them for you using your own
        knowledge base.
      </p>
      <Button
        size="lg"
        asChild
        variant="default"
        className="border-border h-12 cursor-pointer text-base sm:h-14 sm:px-10"
      >
        <Link href="#hero">Join Waitlist</Link>
      </Button>
    </section>
  );
}
