"use client";

import {
  Blocks,
  Bot,
  Brain,
  Mail,
  MapPin,
  Sparkles,
  Twitter,
} from "lucide-react";
import { Card, CardContent } from "@repo/ui/card";

export function Features() {
  return (
    <section
      id="features"
      className="container flex flex-col items-center gap-6 py-24 sm:gap-7"
    >
      <div className="flex flex-col gap-3">
        <span className="text-primary text-center font-bold uppercase">
          Features
        </span>
        <h2 className="font-heading text-center text-3xl font-semibold sm:text-4xl">
          Not your traditional FAQ pages
        </h2>
      </div>
      <p className="text-muted-foreground max-w-2xl text-center text-lg">
        Stop losing time, answering the same questions over and over.
        Faqtory&apos;s AI-powered FAQs will answer them for you using your own
        knowledge base.
      </p>
      <div className="mt-6 grid auto-rows-fr grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3">
        <Card>
          <CardContent className="flex flex-col items-start gap-5 p-7">
            <div className="border-border bg-secondary inline-flex items-center justify-center rounded-md p-2">
              <Sparkles size={28} className="text-primary" />
            </div>
            <div>
              <h4 className="text-foreground mb-2 text-lg font-semibold">
                AI-Powered FAQ Generation
              </h4>
              <p className="text-muted-foreground">
                Create detailed FAQs from your company documents effortlessly.
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex flex-col items-start gap-5 p-7">
            <div className="border-border bg-secondary inline-flex items-center justify-center rounded-md p-2">
              <Twitter size={28} className="text-primary" />
            </div>
            <div>
              <h4 className="text-foreground mb-2 text-lg font-semibold">
                Social Media Automation
              </h4>
              <p className="text-muted-foreground">
                Automate replies to comments on your social media posts using
                our AI-powered FAQs as knowledge base.
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex flex-col items-start gap-5 p-7">
            <div className="border-border bg-secondary inline-flex items-center justify-center rounded-md p-2">
              <Mail size={28} className="text-primary" />
            </div>
            <div>
              <h4 className="text-foreground mb-2 text-lg font-semibold">
                Email automation
              </h4>
              <p className="text-muted-foreground">
                Use AI-driven FAQs as the foundation for automated email
                responses.
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex flex-col items-start gap-5 p-7">
            <div className="border-border bg-secondary inline-flex items-center justify-center rounded-md p-2">
              <Bot size={28} className="text-primary" />
            </div>
            <div>
              <h4 className="text-foreground mb-2 text-lg font-semibold">
                Interactive FAQs &amp; Chatbots
              </h4>
              <p className="text-muted-foreground">
                Transform your FAQs into engaging chatbots for real-time
                customer interactions.
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex flex-col items-start gap-5 p-7">
            <div className="border-border bg-secondary inline-flex items-center justify-center rounded-md p-2">
              <MapPin size={28} className="text-primary" />
            </div>
            <div>
              <h4 className="text-foreground mb-2 text-lg font-semibold">
                Interactive Maps
              </h4>
              <p className="text-muted-foreground">
                Convert location maps into interactive tools that answer
                customer questions.
                <br />
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex flex-col items-start gap-5 p-7">
            <div className="border-border bg-secondary inline-flex items-center justify-center rounded-md p-2">
              <Brain size={28} className="text-primary" />
            </div>
            <div>
              <h4 className="text-foreground mb-2 text-lg font-semibold">
                Dynamic Knowledge Base
              </h4>
              <p className="text-muted-foreground">
                Keep your FAQs current with continuous updates from customer
                queries.
                <br />
              </p>
            </div>
          </CardContent>
        </Card>
        {/* <Card>
          <CardContent className="flex flex-col items-start gap-5 p-7">
            <div className="border-border bg-secondary inline-flex items-center justify-center rounded-md p-2">
              <Blocks size={28} className="text-primary" />
            </div>
            <div>
              <h4 className="text-foreground mb-2 text-lg font-semibold">
                Seamless&nbsp;Integration
              </h4>
              <p className="text-muted-foreground">
                Easily embed FAQs into your website or app for smooth customer
                access.
              </p>
            </div>
          </CardContent>
        </Card> */}
      </div>
    </section>
  );
}
