"use client";

import Link from "next/link";
import { type SiteConfig } from "@/types";
import { usePostHog } from "posthog-js/react";
import { Icons } from "@repo/icons/lucide";

export function Footer({ config: siteConfig }: { config: SiteConfig }) {
  const posthog = usePostHog();

  const captureEvent = (
    eventName: string,
    properties?: Record<string, any>
  ) => {
    posthog?.capture(eventName, properties);
  };

  return (
    <footer className="container mt-10 flex flex-col items-center justify-between gap-6 py-10 sm:flex-row">
      <p className="text-center text-sm">
        © 2024 Faqtory Platform Inc. All rights reserved.
      </p>
      <nav className="flex space-x-4">
        {siteConfig.links.twitter && (
          <Link
            href={siteConfig.links.twitter}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              captureEvent("social_link_clicked", { platform: "twitter" })
            }
          >
            <Icons.twitter className="size-7" />
            <span className="sr-only">Twitter</span>
          </Link>
        )}
        {siteConfig.links.linkedin && (
          <Link
            href={siteConfig.links.linkedin}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              captureEvent("social_link_clicked", { platform: "linkedin" })
            }
          >
            <Icons.linkedIn className="size-7" />
            <span className="sr-only">LinkedIn</span>
          </Link>
        )}
        {siteConfig.links.instagram && (
          <Link
            href={siteConfig.links.instagram}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              captureEvent("social_link_clicked", { platform: "instagram" })
            }
          >
            <Icons.instagram className="size-7" />
            <span className="sr-only">Instagram</span>
          </Link>
        )}
        {siteConfig.links.tiktok && (
          <Link
            href={siteConfig.links.tiktok}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              captureEvent("social_link_clicked", { platform: "tiktok" })
            }
          >
            <Icons.video className="size-7" />
            <span className="sr-only">TikTok</span>
          </Link>
        )}
      </nav>
    </footer>
  );
}
