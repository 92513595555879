"use client";

import { useEffect, useState } from "react";
import Image from "next/image";
import { ArrowRight } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { Icons } from "@repo/icons/lucide";
import { Button } from "@repo/ui/button";
import { Input } from "@repo/ui/input";
import { useToast } from "@repo/ui/use-toast";
import { saveWaitlistSignup } from "@/app/actions/waitlist";

export function Hero() {
  const [isOnWaitlist, setIsOnWaitlist] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const { toast } = useToast();
  const posthog = usePostHog();

  useEffect(() => {
    const savedEmail = localStorage.getItem("waitlistEmail");
    if (savedEmail) {
      setEmail(savedEmail);
      setIsOnWaitlist(true);
    }
  }, []);

  const handleSubmit = async (formData: FormData) => {
    setIsSubmitting(true);
    const email = formData.get("email")?.toString();
    const isValidEmail = z.string().email().safeParse(email).success;
    if (!isValidEmail) {
      setIsSubmitting(false);
      posthog?.capture("waitlist_signup_failed", { reason: "invalid_email" });
      return;
    }
    const result = await saveWaitlistSignup(formData);
    console.log({ result });
    if (result.success) {
      setIsOnWaitlist(true);
      setEmail(email || "");
      localStorage.setItem("waitlistEmail", email || "");
      toast({
        title: "Success!",
        description: "You've been added to the waitlist.",
        variant: "default",
      });
      posthog?.capture("waitlist_signup_success", { email });
    } else {
      setIsOnWaitlist(false);
      toast({
        title: "Error",
        description: "Failed to join the waitlist. Please try again.",
        variant: "destructive",
      });
      posthog?.capture("waitlist_signup_failed", { reason: "server_error" });
    }
    setIsSubmitting(false);
  };

  return (
    <section
      id="hero"
      className="container grid grid-cols-1 gap-8 pb-28 pt-20 xl:grid-cols-2"
    >
      <div className="flex flex-col items-center text-center xl:items-start xl:text-left">
        <div className="bg-secondary hover:bg-secondary/60 flex cursor-pointer items-center gap-1 rounded-full border px-3 py-0.5">
          <span className="text-secondary-foreground text-sm">
            Announcing faqtory.io
          </span>
          <ArrowRight size={16} />
        </div>
        <h1 className="font-heading mt-8 max-w-3xl text-center text-4xl font-semibold sm:text-5xl sm:leading-tight md:text-left">
          AI-powered FAQs
        </h1>
        <p className="text-muted-foreground mt-4 max-w-xl text-center text-lg sm:text-xl xl:text-left">
          Deliver a better, faster, and more consistent customer experience
          while reducing costs and saving time.
        </p>
        <ul className="my-10 flex flex-col gap-4 text-left text-sm md:text-base">
          <li className="flex items-center gap-4">
            <Icons.checkCircle className="text-green-500" />
            <span>
              Uses your business’s data to automatically create FAQs and
              chatbots
            </span>
          </li>
          <li className="flex items-center gap-4">
            <Icons.checkCircle className="text-green-500" />
            <span>
              Includes a collaborative inbox and drafts email responses for your
              team
            </span>
          </li>
          <li className="flex items-center gap-4">
            <Icons.checkCircle className="text-green-500" />
            <span>Handle replies to comments on your social media posts</span>
          </li>
        </ul>
        <form
          action={handleSubmit}
          className="mt-8 flex w-full max-w-md flex-col gap-2 sm:flex-row sm:items-center"
        >
          <Input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="border-border bg-card h-12 px-6 text-lg focus-visible:ring-0 focus-visible:ring-offset-0 sm:h-14 sm:flex-1"
            disabled={isSubmitting || isOnWaitlist}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            size="lg"
            className="h-12 cursor-pointer text-base sm:h-14"
            type="submit"
            variant="default"
            disabled={isSubmitting || isOnWaitlist}
          >
            {isSubmitting
              ? "Submitting..."
              : isOnWaitlist
                ? "👍 Added to Waitlist"
                : "Join Waitlist"}
          </Button>
        </form>
      </div>

      <div className="flex items-center justify-center md:justify-end">
        <div className="w-full">
          <iframe
            src="https://www.youtube.com/embed/9sHoHEQOqns"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="aspect-video h-full w-full rounded-lg shadow-lg"
            onLoad={() => posthog?.capture("hero_video_loaded")}
          ></iframe>
        </div>
      </div>

      <div className="relative col-span-full mt-24 sm:mt-8">
        <Image
          alt="SaaS Dashboard"
          src="/images/faqtory-illustration.svg"
          width={1600}
          height={698}
          priority
        />
        <div className="bg-primary/20 absolute inset-0 -z-10 [filter:blur(180px)]" />
      </div>
    </section>
  );
}
